import React from "react";
import "./SummerCamp.scss";
import Logo from "../../assets/images/logo.svg";
import SummerCampQR from "../../assets/images/summerCampQR.png";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const SummerCamp = () => {
  const navigate = useNavigate();

  return (
    <div className="summerCamp">
      <div className="container">
        <div className="summerCamp_logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-arrow-left"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#000000"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            onClick={() => navigate("/")}
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l14 0" />
            <path d="M5 12l6 6" />
            <path d="M5 12l6 -6" />
          </svg>
          <img onClick={() => navigate("/")} src={Logo} alt="creator-zone" />
        </div>

        <div className="summerCamp_heading">
          <h3>Creator Zone Summer of STEAM 2025</h3>
        </div>

        <div className="summerCamp_topText">
          {/* <p>K-8th Grade Summer Camps</p> */}
          <p>
            We’re thrilled to announce the release of Creator Zone’s 2025 Summer
            of STEAM Camps, where art and technology converge to provide a
            dynamic, well-rounded experience for K-8th grade students.
          </p>
          <p>
            Each camp is thoughtfully designed around engaging STEAM themes,
            blending creativity, innovation, and hands-on learning to inspire
            young minds. Every week features a balance of guided, theme-led
            projects and child-led exploration, encouraging campers to turn
            their ideas into reality.
          </p>
          <p>
            Whether your child is exploring new interests or building on
            existing passions, our camps foster skill-building, curiosity, and
            self-expression. From woodworking and costume design to robotics and
            animation, each session offers opportunities to delve into a diverse
            range of exciting activities. With 8 weeks of flexible summer
            programming, families can choose to enroll in individual weeks or
            multiple sessions for a deeper, more immersive experience. We highly
            recommend pairing a Kids Makerspace Camp with themed camps to expand
            knowledge, enhance hands-on practice, and make the most of our
            advanced makerspace equipment.{" "}
          </p>
          <p>
            At Creator Zone, we’re dedicated to nurturing creativity and
            providing a memorable summer experience that sparks joy, growth, and
            endless possibilities.
          </p>
        </div>
        <div className="summerCamp_paras">
          <div>
            <h4>Week 1 (June 23-27): Kids Makerspace Camp (1):</h4>
            <p>
              Dive into the exciting world of makerspace tools with
              instructor-led guidance on 3D printing, laser cutting, sewing,
              T-shirt printing, and mug heat pressing. Kids will also enjoy
              summer-inspired activities, including engaging art and science
              experiments, slime-making, outdoor painting, fort building, and
              bubble play. A great camp to attend with the other themes to
              master foundational skills on makerspace equipment.{" "}
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/ce065c80-7677-42fe-af16-4a5b12cb6f61/booking">
                Kids Makerspace Camp (1) here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>Week 2 (June 30 - July 4): Arts & Science:</h4>
            <p>
              Explore the relationship between science and art to produce
              creative fusions! Campers will have the opportunity to experiment
              with the science behind popular activities including tie dye,
              paper making & marbling, DIY sidewalk chalk, and exploring the
              captivating world of luminescence with glow-in-the-dark projects.
              Enroll your mad scientists today!
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/ca9897e7-5256-4b0f-971b-b833a27921b1/booking">
                Arts & Science here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>Week 3 (July 7-11) Craft Garden:</h4>
            <p>
              Let’s explore the natural world around us! Campers will have the
              chance to get their hands dirty dreaming up their own fairy
              garden, building bird houses, wind spinners, bubble wands, and
              enjoying the process of mosaic art.{" "}
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/45b8e91b-75c3-4ea2-8d89-f4f5a2e42dfd/booking">
                {" "}
                Craft Garden here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>Week 4 (July 14-18): Wearable Arts Camp</h4>
            <p>
              Calling all cosplayers, fashionistas and aspiring theatre kids.
              Explore the art of textiles and costume creation! Campers will
              design and bring their ideas and characters to life, learning
              sewing techniques and gaining an introduction to fabric
              manipulation while crafting imaginative costumes. Continue to week
              2 of costume design if camper wishes to have more time on their
              projects!{" "}
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/3e5978a1-7e1c-40e0-b33e-c38d252c0346/booking">
                Wearable Arts Camp here
              </a>{" "}
            </p>
          </div>

          {/* <div>
            <h4>Week 5 (July 21-25): Costume Design Camp (2)</h4>
            <p>
              Welcoming new and returning costume design campers! Calling all
              cosplayers, fashionistas and theatre students. Explore the art of
              textiles and costume creation! Campers will design and bring their
              ideas and characters to life, learning sewing techniques and
              gaining an introduction to fabric manipulation while crafting
              imaginative costumes.{" "}
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/45a10faf-ca97-4d75-945d-c8081c880360/booking">
                Costume Design Camp (2) here
              </a>{" "}
            </p>
          </div> */}

          <div>
            <h4>
              Week 6 (July 28 - August 1): Animation & Game Art Design Camp
            </h4>
            <p>
              Discover the world of digital creativity with video game and
              animation design! Highlights include the Airport Minecraft
              Challenge, hands-on practice in 2D and 3D animation, and creating
              original digital projects including game art posters.
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/ecf8d0fc-a7f9-4227-9872-9422109b2c48/booking">
                Animation & Game Art Design Camp here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>Week 7 (August 4-8): Early Introduction to Robotics Camp</h4>
            <p>
              Race, soar, explore! Campers will explore daily exercises that
              teach early concepts of robotics and electronics. They’ll then
              have the chance to put their new learnings to the test by creating
              something that moves—assembling beginner-friendly machines, and
              building STEM kits! With mentors nearby, campers have the
              opportunity to try Laser cutting, 3D printers and crafts available
              at Creator Zone to construct a new invention!
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/8411d92a-8490-4c00-829f-1e8bbfe13048/booking">
                Early Introduction to Robotics Camp here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>
              Week 8 (August 11-15): Lemonade Stand Young Entrepreneur Camp:
            </h4>
            <p>
              Get ready for hands-on creativity and an entrepreneurial
              adventure! At our Lemonade Stand Business Camp, kids will dive
              into the exciting world of business-building as they learn to
              create, design, and run their very own small enterprise. From
              woodworking and T-shirt printing to laser-cut sign making and
              marketing, this camp is brimming with engaging activities and
              essential life skills that will inspire your camper to shine. The
              experience culminates in a Grand Lemonade Sale at the end of the
              week, where campers proudly showcase their hard work to the
              community! A great camp to include with other themes for campers
              to sell and showcase their summer creations.
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/0b600310-1854-4f91-b7fe-98f6dda089e7/booking">
                Lemonade Stand Young Entrepreneur Camp here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>Week 9 (Aug 18-22): Kids Makerspace Camp (2): </h4>
            <p>
              Dive into the exciting world of makerspace tools with
              instructor-led guidance on 3D printing, laser cutting, sewing,
              T-shirt printing, and mug heat pressing. Kids will also enjoy
              summer-inspired activities, including engaging art and science
              experiments, slime-making, outdoor painting, fort building, and
              bubble play. Great for previous campers to attend to finish any
              projects they need some more time & guidance with!
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/03a02ac8-868a-4430-957f-b3d082bfa206/booking">
                Kids Makerspace Camp (2) here
              </a>{" "}
            </p>
          </div>

          <div>
            <h3>
              {/* <svg
                height="28"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                fill="#78bee1"
              >
                <path d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2c0 0 0 0 0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4c0 0 0 0 0 0c19.8 27.1 39.7 54.4 49.2 86.2l160 0zM192 512c44.2 0 80-35.8 80-80l0-16-160 0 0 16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z" />
              </svg>{" "} */}
              CAMP DETAILS & REGISTRATION{" "}
            </h3>
            <h4>EARLY BIRD PRICING UNTIL MARCH 1!</h4>
            <ul className="summerCamp_paras_squareList">
              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b>Best for:</b> K-8th grade students
              </li>

              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b> Camp Hours: </b> 9:00 AM - 3:00 PM
              </li>
              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b> Includes: </b> All materials + take-home projects
              </li>
              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b> Bring: </b> Lunch & two snacks
              </li>
              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b> Sibling Discount: </b> 5% off for each additional child
                (refunded after payment)
              </li>
              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b>Volunteers Welcome! </b> Email{" "}
                <a href="mailto:hello@creatorzone.org">hello@creatorzone.org</a>
              </li>
            </ul>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height="16"
              >
                <path d="M512 80c8.8 0 16 7.2 16 16l0 32L48 128l0-32c0-8.8 7.2-16 16-16l448 0zm16 144l0 192c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16l0-192 480 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24l48 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-112 0z" />
              </svg>{" "}
              <b> Need payment arrangements? </b> We offer{" "}
              <b> deposit & delayed payment options</b>—email{" "}
              <a href="mailto:hello@creatorzone.org">hello@creatorzone.org</a>{" "}
              for details.
            </p>
            <h4 className="summerCamp_paras_noUnderline">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75l-8.7 0-32 0-96 0c-35.3 0-64 28.7-64 64l0 96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32l64 0c17.7 0 32-14.3 32-32l0-128 8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-147.6c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4L480 32zm-64 76.7L416 240l0 131.3C357.2 317.8 280.5 288 200.7 288l-8.7 0 0-96 8.7 0c79.8 0 156.5-29.8 215.3-83.3z" />
              </svg>
              After Registration:
            </h4>
            <p>
              {" "}
              Our team will reach out with <b>intake forms</b>.
            </p>
            <h4 className="summerCamp_paras_noUnderline">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
              </svg>
              Questions?
            </h4>
            <p>
              Email us at{" "}
              <a href="mailto:hello@creatorzone.org">hello@creatorzone.org</a>{" "}
              or send us your <b> phone number and best contact time </b> for a
              callback.
            </p>
            <p>
              {" "}
              We can't wait to make this summer an <b> unforgettable </b>{" "}
              experience!
            </p>
            <h4 className="summerCamp_paras_noUnderline">- The CZ Team</h4>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummerCamp;
