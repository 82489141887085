import React, { useState } from "react";
import "./Footer.scss";
import LogoIcon from "../../assets/images/logo.svg";
import TextInput from "../TextInput";
import Button from "../Button";
import Path1 from "../../assets/images/footer_path1.svg";
import Path2 from "../../assets/images/footer_path2.svg";
import Path3 from "../../assets/images/footer_path3.svg";
import Facebook from "../../assets/images/footer_fb.svg";
import Instagram from "../../assets/images/footer_instagram.svg";
import Discord from "../../assets/images/FooterDiscord.svg";
import Tiktok from "../../assets/images/footer_tiktok.svg";
import Yellow from "../../assets/images/footer_yellow.svg";
import Mustard from "../../assets/images/footer_mustard.svg";
import BlueCircle from "../../assets/images/footer_blue.svg";
import WhiteCircle from "../../assets/images/footer_white.svg";
import BlueDots from "../../assets/images/footer_dots.svg";
import FooterMediaBlue from "../../assets/images/footer_media_blue.svg";
import { postUserInfo } from "../../apis/backend_helper";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await postUserInfo({ email });
      setIsLoading(false);
      if (res?.ok) {
        setEmail("");
      }
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }
  };

  return (
    <footer className="footer">
      <img src={BlueCircle} alt="blue" className="footer_blue" />
      <img src={WhiteCircle} alt="white" className="footer_white" />
      <img src={BlueDots} alt="blue" className="footer_dots" />
      <div className="container">
        <div className="footer_formContainer">
          <img src={LogoIcon} className="footer_icon" alt="logo" />
          <a href="mailto:hello@creatorzone.org">hello@creatorzone.org</a>
          <p>Get the latest updates about our new services.</p>
          <form onSubmit={handleSubmit}>
            <TextInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Add your email address"
              required
            />
            <Button type="submit">
              {isLoading ? "Signing up..." : "Sign me up"}
            </Button>
          </form>
        </div>
        <div className="footer_mediaContainer">
          <h3>Join us on social</h3>
          <div className="footer_media">
            <img src={Path1} alt="path" className="footer_mediaPath1" />
            <img src={Path2} alt="path" className="footer_mediaPath2" />
            <img src={Path3} alt="path" className="footer_mediaPath3" />
            <a
              href="https://www.facebook.com/CreatorZoneHQ/"
              target="_blank"
              className="footer_mediaFb"
              rel="noreferrer"
            >
              <img src={Facebook} alt="fb" />
            </a>
            <a
              href="https://www.instagram.com/creatorzonehq/"
              target="_blank"
              className="footer_mediaInstagram"
              rel="noreferrer"
            >
              <img src={Instagram} alt="instagram" />
            </a>
            <a
              href="https://www.tiktok.com/@creatorzonehq"
              target="_blank"
              className="footer_mediaTiktok"
              rel="noreferrer"
            >
              <img src={Tiktok} alt="tiktok" />
            </a>
            {/* <a
              href="https://discord.gg/83HkE3nNBA"
              target="_blank"
              className="footer_mediaPeople"
              rel="noreferrer"
            >
              <img src={Discord} alt="discord" />
            </a> */}
            <img src={Yellow} alt="yellow" className="footer_mediaYellow" />
            <img src={Mustard} alt="mustard" className="footer_mediaMustard" />
            <img
              src={FooterMediaBlue}
              alt="blue"
              className="footer_mediaBlue"
            />
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <p>
          Creator Zone is a registered 501(c)(3) in WA State EIN: 92-0846911
        </p>
        {/* <p>
          <a href="#">Terms of Service</a>
          <a href="#"> Privacy Policy</a>
        </p> */}
      </div>
    </footer>
  );
};

export default Footer;
